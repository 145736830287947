import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const routes = [
  {
    path: '/smartSocket',
    name: 'smartSocket',
    component: () => import('@/views/smart-socket.vue'),
    meta: {
      title: '智能插座',
    }
  },
  {
    path: '/smartModula',
    name: 'smartModula',
    component: () => import('@/views/smart-modulator.vue'),
    meta: {
      title: '控制器',
    }
  },
  {
    path: '/smartToothbrush',
    name: 'smartToothbrush',
    component: () => import('@/views/smart-toothbrush.vue'),
    meta: {
      title: '牙刷',
    }
  }
]



const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
